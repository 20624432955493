<template>
  <form
    action="#"
    @submit.prevent="submitNotification"
    novalidate
    class="notif-create"
  >
    <div>
      <custom-textarea
        :height="100"
        name="message"
        error="message"
        label="Текст"
        v-model="form.message"
      />
      <custom-input
        v-model="form.send_at"
        name="send_at"
        type="datetime-local"
        label="Время отправки"
      />
    </div>
    <div class="staff-form__btns mt-25 mb-10 display--flex justify-content-end">
      <button type="submit" class="btn btn-accent mr-10">Создать</button>
      <button type="button" @click="router.go(-1)" class="btn btn-w">
        Назад
      </button>
    </div>
    <div class="form-preloader" v-if="isPreloader"><preloader /></div>
  </form>
</template>

<script>
import { useStore } from "vuex";
import { reactive, ref } from "@vue/reactivity";

import { computed } from "@vue/runtime-core";
import CustomTextarea from "../Fields/CustomTextarea.vue";
import Preloader from "../../Technical/Preloader.vue";
import useErrors from "../../../mixins/useErrors";
import standartError from "../../../mixins/standartError";
import { useRouter } from "vue-router";
import CustomInput from "../Fields/CustomInput.vue";

export default {
  components: { CustomTextarea, Preloader, CustomInput },
  name: "create-notification-form",
  setup() {
    const store = useStore(),
      router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors();
    const profile = computed(() => store.state.profile.profile);
    const form = reactive({
        message: "",
        send_at: null,
      }),
      isPreloader = ref(false);

    const submitNotification = () => {
      isPreloader.value = true;
      store
        .dispatch("notifications/createNotification", form)
        .then(() => (isPreloader.value = false))
        .catch(standartError(setErrors, clearCustomErrors, isPreloader));
    };

    return {
      form,
      profile,
      router,
      submitNotification,
      isPreloader,
    };
  },
};
</script>

<style lang="scss" scoped>
.notif-create {
  .form-group:nth-child(2) {
    margin-top: 25px;
  }
}
</style>